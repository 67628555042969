import React, { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useRedirect,
    useDataProvider,
    DateTimeInput,
    useGetList,
    CreateBase, Form, Toolbar,
    useNotify,
    Confirm,
} from 'react-admin';
import { endpoint, wsEndpoint } from '../App';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Icon,
    Link,
    Box,
    Button
} from '@mui/material';
import { Link as RaLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useSound } from 'use-sound';
import CallIcon from '@mui/icons-material/Call';
import TelegramIcon from '@mui/icons-material/Telegram';

export const CallButton = ({contact_phone, type='icon', confirmPopup=false, telegram_username, add_text}) => {  
const notify = useNotify();
const [open, setOpen] = useState(false);

const handleClick = (event) => {
    event.stopPropagation();
    /*
    if(confirmPopup){
        setOpen(true);
    }else{
        fetchData();
    }*/
};
const handleDialogClose = () => setOpen(false);
const handleConfirm = () => {
    fetchData();
    setOpen(false);
};

const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); 
      const id = localStorage.getItem('id');

      /*const response = await axios.post(
        `${endpoint}/ats/mango/call`,
        {
            contact_phone: contact_phone,
            manager_account_id: id
        },
        {
          headers: {
            Authorization: `${token}`
          },
        }
      );*/
      const response = await axios.post(
        `${endpoint}/ats/telphin/call`,
        {
            contact_phone: contact_phone,
            manager_account_id: id
        },
        {
          headers: {
            Authorization: `${token}`
          },
        }
      );
      // Check if the response data is different from the current notifications
      console.log(new Date().toISOString() + " " + JSON.stringify(response.data));
      notify('Запрос на звонок отправлен в АТС...', { type: 'info', undoable: false });
    } catch (error) {
      console.error('Fetch failed:', error);
    }
};

if(contact_phone){
  return (
    <>
    {type === 'icon' ?
    <IconButton onClick={handleClick}>
    <CallIcon />
    </IconButton>
    :
    <Link
    variant="body1"
    onClick={handleClick}
    sx={{ cursor: 'pointer' }}
    >
    {contact_phone}
    </Link>
    }
    {confirmPopup ? 
    <Confirm
                isOpen={open}
                title={`Уверены?`}
                content={`Звонок через Телфин АТС`}
                cancel={`Нет, отмена`}
                confirm={`Да, начать звонок`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            /> 
    : null
    }
    </>
  );
}

if(telegram_username){
  return(
    <RaLink to={`https://t.me/${telegram_username.slice(1)}`} target='_blank' style={{ display: "inline-block" }}> 
      <Box display="flex" flexDirection="row" alignItems="center">
        <TelegramIcon color="primary" />
        <Button variant="text" color="primary" sx={{ textTransform: 'none' }}>
          {add_text} {telegram_username}
        </Button>
      </Box>
    </RaLink>
  );
}

return null;

};