import * as React from 'react';
import { useEffect, useState } from 'react';
import { CreateBase, Form, Toolbar } from 'react-admin';
import { Card, CardContent, Box, Avatar } from '@mui/material';

import { ContactInputs } from './ContactInputs';
import { Contact } from '../types';

const ContactCreate = () => {

    return(
    <CreateBase
        redirect="list"
        mutationMode="optimistic"
        transform={(data) => ({
            ...data,
            updated_at: new Date(),
            ap_id: localStorage.getItem('id'),
            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
        })}
    >
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <ContactInputs />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    </CreateBase>
);
}

export default ContactCreate;
